import { useEffect, useState } from 'react';
import { lambda } from 'src/_shared';

function MetabaseDashboardEmbed({companyId}) {

	const [iFrameUrl, setFrameUrl] = useState();


	useEffect(() => {
		const getReportUrl = async () => {
			const data = await lambda({
				endpoint: 'metabaseEmbeds',
				variables: {
					companyId: companyId,
					reportType: "dashboard",
					reportId: 13,
				},
			});
			setFrameUrl(data.iframeUrl);
		};
		getReportUrl();
	}, [companyId]);


	return (
		<iframe
			src={iFrameUrl}
			frameBorder="0"
			width={"100%"}
			height={"2050"}
			title="Metabase Dashboard"
		/>
	);
}

export default MetabaseDashboardEmbed;