import { Card, Col, Row, Tooltip } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { ImInterestedButton } from 'src/_shared/components/im-interested-send-button';
import { ShareSendButton } from 'src/_shared/components/share-send-button';
import { USER_ROLES } from 'src/_shared/constants';
import { JobLocation } from 'src/_shared/index.js';
import { ReferralModal } from 'src/_shared/referral-modal';
import { ReferralRequestModal } from 'src/_shared/referral-request-modal';
import { calculateReferralBonus, ml } from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors';
import { companyNameText, jobMatch, jobMatchNumber } from './jobCardStyles.js';
import PayoutDaysDisplay from 'src/_shared/components/index.js';

class JobCardComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			matches: null,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			translatedTitle: '',
		};
	}

	async componentDidMount() {
		const job = get(this.props, 'job');
		const jobMatches = get(this.props, 'job.jobMatches', []);
		if (get(job, 'jobType')) {
			this.setState({ job });
		}

		const newTitle = get(job, 'title');
		this.setState({
			translatedTitle: newTitle,
		});
		if (job) {
			const matches = jobMatches.filter(
				(jobMatch) =>
					get(jobMatch, 'userId') === get(this.props, 'currentUser.id') &&
					get(jobMatch, 'relevance') >= 30 &&
					get(jobMatch, 'matchStatus') !== false
			);
			this.setState({ matches });
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !== this.props.currentUser.languageCode
		) {
			const newTitle = get(this.props, 'job.title');
			this.setState({
				translatedTitle: newTitle,
			});
		}
	}

	render() {
		const {
			allMultiLingualData,
			currentUser,
			onUpdateJob,
			onCreateReferral,
			contactIncentiveBonus,
			contacts,
			navLocation,
			allowSelfReferrals,
			contactsNextToken,
			currentCurrencyRate,
			currentCurrencySymbol,
			currentUserCompany,
			isExtendedUser,
			allowSelfReferralsInternalLink,
			onCreateUserJobShare,
			userJobShares,
			onUpdateUserJobShare,
			setUpdatedContacts,
			hideSubcompanyOnJobs,
		} = this.props;

		const extendedNetworkUsers = get(
			this.props,
			'currentUser.extendedContactData',
			[]
		).filter(
			(user) => get(user, 'user.companyId') === get(this.props, 'job.companyId')
		);
		const job = get(this.props, 'job');
		const subCompanyId = get(job, 'subCompanyId', null);
		const subCompanyName = get(job, 'subCompany.name', '');
		const campaignId = get(job, 'campaignId');
		const campaign = get(job, 'campaign');

		const archived = get(job, 'campaign.archived', false);
		const isHotJob = get(job, 'isHotJob', false);
		let currentTieredBonus = '';

		currentTieredBonus =
			!campaign || !campaign.id || campaign.archived
				? get(this.props, 'job.tieredBonus')
				: get(job, 'campaign.tieredBonus');
		const jobPublicLink = get(job, 'publicLink');
		const id = get(job, 'id');
		const referredBy = get(currentUser, 'id');
		let languageCode = get(currentUser, 'languageCode', 'US');
		if (
			languageCode === 'US' ||
			languageCode === null ||
			languageCode === undefined
		) {
			languageCode = 'EN';
		}

		const { origin } = window.location;
		const jobUrl = `${origin}/newreferral/${referredBy}/${id}/${languageCode}`;
		const publicLink = jobUrl;
		const internalJobLink = get(job, 'internalJobLink');
		const title = get(job, 'title');
		const department = get(job, 'department');
		const departmentId = get(job, 'departmentId');
		const company = get(job, 'company');
		const jobType = get(job, 'jobType');
		const referrals = get(job, 'referrals');
		const description = get(job, 'description');
		const status = get(job, 'status');
		const shares = get(job, 'shares');
		const companyId = get(company, 'id');
		const referralBonus = get(this.props, 'job.referralBonus');
		const location = get(this.props, 'job.job.location');
		const locations = get(this.props, 'job.job.locations', []);
		const { loading, matches, theme } = this.state;
		let userGroupCurrency = get(currentUser, 'userGroup.currency');
		const disableReferrals = get(
			currentUser,
			'company.disableReferrals',
			false
		);
		userGroupCurrency ??= 'USD';
		const getBonusAmount = (
			contactIncentiveBonus,
			referralBonusAmount,
			incentiveEligible,
			currentTieredBonus,
			employee,
			userGroupId
		) => {
			const bonusValue = calculateReferralBonus(
				contactIncentiveBonus,
				referralBonusAmount,
				incentiveEligible,
				currentTieredBonus,
				employee,
				userGroupId
			);
			return `${getSymbolFromCurrency(userGroupCurrency)}${bonusValue}`.replaceAll(
				/\B(?=(\d{3})+(?!\d))/g,
				','
			);
		};

		const isGeneralReferral = get(job, 'isGeneralReferral', false);
		const generalReferralJob = () => {
			return (
				<li>
					<Tooltip
						arrowPointAtCenter
						placement="topRight"
						title={ml(
							'General Jobs represent multiple openings. A new referral and bonus (if applicable) will be created when the candidate is moved to a different job.',
							currentUser,
							allMultiLingualData
						)}
					>
						<span className="general-job-tag">
							{ml('General Job', currentUser, allMultiLingualData)}
						</span>
					</Tooltip>
				</li>
			);
		};

		const maxWidth = isExtendedUser ? '100%' : 205;
		const hideInterestedForGroup = get(
			this.props,
			'currentUser.company.hideInterestedForGroup'
		);
		let filteredUserGroups = [];
		let isHideImInterestedForEmp = false;
		if (
			hideInterestedForGroup !== null &&
			hideInterestedForGroup !== undefined
		) {
			filteredUserGroups = JSON.parse(hideInterestedForGroup);
			const userGroupId = get(this.props, 'currentUser.userGroup.id');
			isHideImInterestedForEmp = filteredUserGroups.some(function (value) {
				return value.id === userGroupId;
			});
		}

		const hideShareLinkForDepartment = get(
			currentUserCompany,
			'hideShareLinkForDepartment'
		);
		let hideDepartmentShareLink = false;
		if (hideShareLinkForDepartment) {
			const jsonData = JSON.parse(hideShareLinkForDepartment);
			hideDepartmentShareLink = jsonData.some(function (value) {
				return value.id === departmentId;
			});
		}

		const hideShareLinkNoPublicUrl = get(
			currentUserCompany,
			'hideShareLinkNoPublicUrl'
		);

		const payoutDaysTiers = get(this.props, 'job.tieredBonus.tiers', []);
		const campaignPayoutDaysTiers = get(
			this.props,
			'job.campaign.tieredBonus.tiers',
			[]
		);
		const tieredBonusName = get(this.props, 'job.tieredBonus.name', '');
		const campaignTieredBonusName = get(
			this.props,
			'job.campaign.tieredBonus.name',
			''
		);

		const totalTags =
			(payoutDaysTiers.length > 0 ? 1 : 0) +
			(campaignPayoutDaysTiers.length > 0 ? 1 : 0);

		return (
			<Card
				bordered={false}
				bodyStyle={{ padding: 0, height: '100%' }}
				className="job-card"
				style={isHotJob ? { borderColor: '#f7a34d' } : {}}
			>
				<div className="job-card-inner">
					{!loading && (
						<div className="job-card-header">
							<div className="job-title-wrap">
								<h4 className="job-title">
									<Link
										to={{
											pathname: `/browsejobs/${id}`,
											state: {
												referrals,
												currentUserCompany,
											},
										}}
									>
										{this.state.translatedTitle}
									</Link>
								</h4>
								<ul className="actions">
									{isHotJob == true && (
										<li>
											<i className="icon-fire text-orange" />
										</li>
									)}

									{isGeneralReferral
										? generalReferralJob()
										: referralBonus &&
											referralBonus.hasBonus &&
											!isExtendedUser && (
												<li>
													<span className="amount">
														{getBonusAmount(
															contactIncentiveBonus,
															referralBonus.amount,
															currentUser
																? currentUser.incentiveEligible
																: this.props.currentUser.incentiveEligible,
															currentTieredBonus
																? currentTieredBonus
																: this.props.currentTieredBonus,
															'employee',
															get(currentUser, 'userGroupId')
														)}
													</span>
												</li>
											)}
								</ul>
							</div>

							{isExtendedUser && (
								<div className={companyNameText}>
									{get(this.props, 'job.company.name')}
								</div>
							)}

							{!this.props?.currentUserCompany?.hidePaymentInfoJobCard && (
								<div
									className="mb-4 d-flex"
									style={{ justifyContent: 'flex-end' }}
								>
									<PayoutDaysDisplay
										currentUser={currentUser}
										allMultiLingualData={allMultiLingualData}
										tiers={payoutDaysTiers}
										tieredBonusName={tieredBonusName}
										totalTags={totalTags}
									/>
								</div>
							)}

							<div className="job-info-wrap">
								<ul className="job-info">
									<JobLocation
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										location={location}
										locations={locations}
									/>

									{!isExtendedUser && (
										<li>
											<i className="icon-folder" />
											<span>{department ? department.name : ''}</span>
										</li>
									)}
									{subCompanyId !== null &&
										(!hideSubcompanyOnJobs ||
											currentUser?.role !== 'employee') && (
											<li>
												<i className="icon-building" />
												<span>{subCompanyName}</span>
											</li>
										)}
									{/* If company is ZT systems */}
									{companyId === 'e600195e-d1d9-4163-8b22-f0f250c4bcfd' &&
										get(job, 'displayReqId') !== true &&
										get(job, 'displayReqId') !== '' &&
										get(job, 'displayReqId') && (
											<li>
												{'ID#: '}
												{get(job, 'displayReqId')}
											</li>
										)}
									{companyId !== 'e600195e-d1d9-4163-8b22-f0f250c4bcfd' &&
										get(job, 'externalJobId') !== true &&
										get(job, 'externalJobId') !== '' &&
										get(job, 'externalJobId') && (
											<li>
												{'ID#: '}
												{get(job, 'externalJobId')}
											</li>
										)}
								</ul>
								{!get(currentUserCompany, 'disableShareLink') &&
									status === 'open' &&
									!isExtendedUser &&
									!hideDepartmentShareLink &&
									(!hideShareLinkNoPublicUrl ||
										(hideShareLinkNoPublicUrl && jobPublicLink)) && (
										<span className="share">
											<ShareSendButton
												iconOnly
												allMultiLingualData={allMultiLingualData}
												theme={theme}
												title={title}
												jobId={id}
												jobUrl={publicLink}
												company={company}
												jobType={jobType}
												description={description}
												city={location ? location.city : null}
												state={location ? location.state : null}
												shares={shares}
												referredBy={referredBy}
												languageCode={languageCode}
												currentUser={this.props.currentUser}
												userJobShares={userJobShares}
												onUpdateJob={onUpdateJob}
												onCreateUserJobShare={onCreateUserJobShare}
												onUpdateUserJobShare={onUpdateUserJobShare}
											/>
										</span>
									)}
							</div>

							{get(currentUserCompany, 'disableSmartReferrals') !== true &&
								matches &&
								matches.length > 0 &&
								!isExtendedUser && (
									<Row type="flex" className={jobMatch}>
										<WebIcon
											name="tick-inside-circle"
											color={COLORS.green}
											size={14}
										/>
										<span className={jobMatchNumber}>{matches.length}</span>{' '}
										Smart Referrals
									</Row>
								)}
						</div>
					)}

					{status === 'open' && (
						<div className="job-card-footer ateet">
							{status === 'open' && isExtendedUser && (
								<Row gutter={5}>
									<Col span={24}>
										<div
											style={{
												textAlign: 'center',
												color: COLORS.lightGray4,
												height: 40,
												marginTop: 10,
												marginBottom: -10,
											}}
										>
											{ml(
												'You are connected to',
												currentUser,
												allMultiLingualData
											)}{' '}
											<strong style={{ color: COLORS.gray }}>
												{extendedNetworkUsers.length}
											</strong>{' '}
											{extendedNetworkUsers.length === 1
												? ml('employee', currentUser, allMultiLingualData)
												: ml('employees', currentUser, allMultiLingualData)}
											.
										</div>
										<ReferralRequestModal
											allMultiLingualData={allMultiLingualData}
											extendedNetworkUsers={extendedNetworkUsers}
											contacts={contacts}
											test="test"
											job={job}
											referrals={get(job, 'referrals', [])}
										/>
									</Col>
								</Row>
							)}
							{status === 'open' && !isExtendedUser && (
								<>
									<ReferralModal
										disabled={disableReferrals && jobPublicLink === null}
										contacts={contacts}
										setUpdatedContacts={setUpdatedContacts}
										contactsNextToken={contactsNextToken}
										navLocation={navLocation}
										jobData={job}
										job={job}
										buttonType="large"
										isBtnBlock="true"
										referrals={get(job, 'referrals', [])}
										currentCurrencyRate={currentCurrencyRate}
										currentCurrencySymbol={currentCurrencySymbol}
										contactIncentiveBonus={contactIncentiveBonus}
										campaignId={campaignId}
										test="test"
										onUpdateJob={onUpdateJob}
										onCreateUserJobShare={onCreateUserJobShare}
										onUpdateUserJobShare={onUpdateUserJobShare}
									/>

									{get(this.props, 'currentUser.role') ===
										USER_ROLES.EMPLOYEE && isHideImInterestedForEmp
										? null
										: allowSelfReferrals &&
											((allowSelfReferralsInternalLink && internalJobLink) ||
												!allowSelfReferralsInternalLink) && (
												<ImInterestedButton
													key={job.id}
													disabled={get(job, 'hideImInterested')}
													allMultiLingualData={allMultiLingualData}
													contacts={contacts}
													currentUser={this.props.currentUser}
													internalJobLink={internalJobLink}
													job={job}
													onCreateReferral={onCreateReferral}
												/>
											)}
								</>
							)}
						</div>
					)}
				</div>
			</Card>
		);
	}
}

export default withApollo(JobCardComponent);
