import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withGetCompany } from 'src/_shared/api/components/settings/';
import {
	AuthenticatedRoute,
	USER_ROLES,
	compose,
	lambda,
} from 'src/_shared/index.js';
import { dashboardActions } from 'src/actions.js';
import store from 'src/state.js';

const CustomReportComponent = ({ report }) => {
	const [reportUrl, setReportUrl] = useState('');
	useEffect(() => {
		const getReportUrl = async () => {
			const response = await lambda({
				endpoint: 'metabaseEmbeds',
				variables: {
					reportId: report.reportId,
					reportType: report.reportType,
				},
			});
			setReportUrl(response.iframeUrl);
		};

		getReportUrl();
	}, []);
	return (
		<iframe
			allowtransparency
			src={reportUrl}
			frameBorder="0"
			width="100%"
			height="600"
		/>
	);
};

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

const CustomReportWithAPI = compose(withGetCompany)(CustomReportComponent);

const CustomReport = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(CustomReportWithAPI);

export const CustomReportRoutes = (props) => {
	const state = store.getState();
	const { currentUser } = state.user;
	const metabaseReports = state.user.currentUser?.company?.metabaseReports;
	if (!metabaseReports?.length) return null;

	return (
		<>
			{metabaseReports.map((report) => {
				const path = `/custom-report/${report.label.toLowerCase()?.replaceAll(' ', '-')}`;

				return (
					<AuthenticatedRoute
						key={report.label}
						exact
						path={path}
						roles={[
							USER_ROLES.ADMIN,
							USER_ROLES.SUPER_ADMIN,
							USER_ROLES.SUPPORT_ADMIN,
						]}
						component={(props) => (
							<CustomReport
								{...props}
								report={report}
								currentUser={currentUser}
							/>
						)}
					/>
				);
			})}
		</>
	);
};
