import { Avatar, Tooltip } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useCallback, useState } from 'react';
import note from 'src/_shared/assets/note.png';
import PayoutDaysDisplay from 'src/_shared/components/index';
import { ml, translation } from 'src/_shared/services/utils';

const getBonusTagColor = (bonusStatus) => {
	const bonusStatusColors = {
		pending: 'var(--tan-hide)',
		needsReview: 'var(--mellow-yellow)',
		earned: 'var(--fern)',
		ineligible: 'var(--sunset-orange)',
		paid: 'var(--forest-green)',
	};
	return bonusStatusColors[bonusStatus] || 'default';
};

const BonusInfo = ({
	allMultiLingualData,
	referral,
	myReferralBonus,
	totalBonus,
	currentTieredBonus,
	currentUser,
}) => {
	const [tooltipTitle, setTooltipTitle] = useState(referral?.adminNote || '');
	const languageCode = currentUser?.languageCode;
	const currencyCode = currentUser?.userGroup?.currency || 'USD';

	const handleTooltipVisibleChange = useCallback(
		async (visible) => {
			if (visible) {
				if (
					languageCode &&
					languageCode !== 'EN' &&
					languageCode !== 'US' &&
					referral?.adminNote
				) {
					try {
						const adminNoteTranslated = await translation(referral.adminNote, {
							languageCode,
						});
						setTooltipTitle(adminNoteTranslated);
					} catch (error) {
						console.error(error);
						setTooltipTitle(referral.adminNote);
					}
				} else {
					setTooltipTitle(referral.adminNote);
				}
			}
		},
		[referral?.adminNote, languageCode]
	);

	const hidePaymentInfoReferralCard =
		currentUser?.company?.hidePaymentInfoReferralCard || null;

	const potentialBonus =
		currentTieredBonus?.tiers?.reduce((total, tier) => {
			try {
				if (typeof tier !== 'string') {
					console.warn('Tier is not a valid string:', tier);
					return total;
				}

				const parsedTier = JSON.parse(tier);

				if (parsedTier?.userGroup === currentUser?.userGroupId) {
					return total + (parsedTier.amount || 0);
				}

				return total;
			} catch (error) {
				console.error('Invalid JSON string:', tier, error);
				return total;
			}
		}, 0) || 0;

	return (
		<div className="bonus-info">
			{referral.adminNote && (
				<Tooltip
					key={`${referral.id}-admin-note-2`}
					arrowPointAtCenter
					placement="topLeft"
					title={tooltipTitle}
					onOpenChange={handleTooltipVisibleChange}
				>
					<img className="admin-note-icon" alt="admin-note" src={note} />
				</Tooltip>
			)}
			{referral.status !== 'ineligible' &&
				referral.status !== 'notHired' &&
				referral.status !== 'declined' && (
					<>
						{myReferralBonus.length > 0 ? (
							<>
								<Avatar.Group>
									{myReferralBonus
										.sort(
											(a, b) => new Date(a.earnedDate) - new Date(b.earnedDate)
										)
										.map((bonus, index) => (
											<Tooltip
												key={index}
												title={`${bonus.bonusStatus.charAt(0).toUpperCase()}${bonus.bonusStatus.slice(
													1
												)}: ${ml('Payment', currentUser, allMultiLingualData)} ${bonus.payment}`}
											>
												<Avatar
													style={{
														backgroundColor: getBonusTagColor(
															bonus.bonusStatus
														),
													}}
												>
													{bonus.bonusStatus.charAt(0).toUpperCase()}
												</Avatar>
											</Tooltip>
										))}
								</Avatar.Group>

								{totalBonus > 0 && (
									<span className="total-bonus">
										{getSymbolFromCurrency(currencyCode)}
										{totalBonus.toLocaleString()}
									</span>
								)}
								{referral?.status !== 'hired' && (
									<span className="total-bonus">
										{getSymbolFromCurrency(currencyCode)}
										{referral?.bonus?.amount}
									</span>
								)}
							</>
						) : (
							<PayoutDaysDisplay
								currentUser={currentUser}
								allMultiLingualData={allMultiLingualData}
								tiers={
									currentTieredBonus?.tiers ??
									currentTieredBonus?.tieredBonus?.tiers ??
									[]
								}
							/>
						)}
					</>
				)}
			{referral?.status === 'hired' ||
			referral?.status === 'notHired' ||
			referral?.status === 'declined' ||
			potentialBonus <= 0 ||
			potentialBonus === undefined ? null : (
				<span className="total-bonus">
					{getSymbolFromCurrency(currencyCode)}
					{potentialBonus}
				</span>
			)}
		</div>
	);
};

export default BonusInfo;
