import { Button, Collapse, Popconfirm, Radio, message } from 'antd';
import Form from 'antd/lib/form/Form.js';
import Input from 'antd/lib/input/Input.js';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const CustomReportSettingsComponent = (props) => {
	const [customReports, setCustomReports] = useState(
		props.company?.metabaseReports ?? []
	);
	const [activeBonusKey, setActiveBonusKey] = useState(null);
	const [editingReport, setEditingReport] = useState(null);

	const addCustomReport = () => {
		if (editingReport) {
			return;
		}

		const id = uuidv4();
		setCustomReports([
			...customReports,
			{ id, label: '', reportId: '', reportType: '' },
		]);
		setActiveBonusKey(id);
		setEditingReport(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const newReport = customReports.find(
			(report) => report.id === activeBonusKey
		);
		if (!newReport?.label || !newReport?.reportId || !newReport?.reportType) {
			console.error('Missing required fields');
			return;
		}

		try {
			// Ensure the data matches MetabaseReportInput exactly
			const cleanedReports = customReports.map((report) => ({
				id: report.id,
				label: report.label,
				reportId: report.reportId,
				reportType: report.reportType,
			}));

			const input = {
				id: props.currentUser.companyId,
				metabaseReports: cleanedReports,
			};
			await props.onUpdateCompany({ input });
			props.updateUserCompany({
				...props.currentUser.company,
				metabaseReports: cleanedReports,
			});
			message.success('Report added successfully');
		} catch (error) {
			console.error('Error adding report to company:', error);
			// Log the detailed error if available
			if (error.graphQLErrors) {
				console.error('GraphQL Errors:', error.graphQLErrors);
			}

			message.error('Failed to add report to company');
		} finally {
			setEditingReport(false);
			setActiveBonusKey(null);
		}
	};

	const handleDelete = async (id) => {
		const reportToRemove = customReports.find((report) => report.id === id);

		if (reportToRemove) {
			try {
				const cleanedReports = customReports
					.filter((report) => report.id !== id)
					.map((report) => ({
						id: report.id,
						label: report.label,
						reportId: report.reportId,
						reportType: report.reportType,
					}));
				const input = {
					id: props.companyId,
					metabaseReports: cleanedReports,
				};
				await props.onUpdateCompany({ input });
				props.updateUserCompany({
					...props.currentUser.company,
					metabaseReports: cleanedReports,
				});
				setCustomReports(cleanedReports);
				message.success('Report deleted successfully');
			} catch (error) {
				console.error('Error deleting report from company:', error);
				// Log the detailed error if available
				if (error.graphQLErrors) {
					console.error('GraphQL Errors:', error.graphQLErrors);
				}

				message.error('Failed to delete report from company');
			} finally {
				setEditingReport(false);
				setActiveBonusKey(null);
			}
		}

		setEditingReport(false);
	};

	const renderReports = () => {
		return customReports.map((report) => (
			<Collapse.Panel
				key={report.id}
				className="bonus-collapse-card"
				extra={
					<div className="bonus-collapse-icon-wrap">
						<i className="icon-arrow-down bonus-collapse-icon" />
					</div>
				}
				header={<h4>{report.label}</h4>}
			>
				<div className="bonus-rule-actions">
					<Button type="primary" onClick={(e) => handleSubmit(e)}>
						Save Report
					</Button>
					<Popconfirm
						title="Delete Report?"
						placement="bottom"
						okText="Yes"
						cancelText="No"
						onConfirm={() => handleDelete(report.id)}
					>
						<Button ghost className="ant-btn-danger">
							<i className="icon-bin" />
							Delete Report
						</Button>
					</Popconfirm>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '10px',
							alignItems: 'baseline',
						}}
					>
						<label
							className="bonus-rule-label"
							htmlFor="report-label"
							style={{ flexBasis: '100px' }}
						>
							Report Label
						</label>
						<Input
							className="report-label"
							placeholder="Report Label"
							value={report.label}
							style={{ flexBasis: '200px', flexGrow: 1 }}
							onChange={(e) => {
								setCustomReports(
									customReports.map((r) =>
										r.id === report.id ? { ...r, label: e.target.value } : r
									)
								);
							}}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '10px',
							alignItems: 'baseline',
						}}
					>
						<label
							className="bonus-rule-label"
							htmlFor="report-id"
							style={{ flexBasis: '100px' }}
						>
							Report ID
						</label>
						<Input
							className="report-id"
							placeholder="Report ID"
							value={report.reportId}
							style={{ flexBasis: '200px', flexGrow: 1 }}
							onChange={(e) => {
								setCustomReports(
									customReports.map((r) =>
										r.id === report.id ? { ...r, reportId: e.target.value } : r
									)
								);
							}}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '10px',
							alignItems: 'baseline',
						}}
					>
						<label
							className="bonus-rule-label"
							htmlFor="report-type"
							style={{ flexBasis: '100px' }}
						>
							Report Type
						</label>
						<Radio.Group
							className="report-type"
							value={report.reportType}
							style={{ flexBasis: '200px', flexGrow: 1 }}
							onChange={(e) => {
								setCustomReports(
									customReports.map((r) =>
										r.id === report.id
											? { ...r, reportType: e.target.value }
											: r
									)
								);
							}}
						>
							<Radio value="dashboard">Dashboard</Radio>
							<Radio value="question">Question</Radio>
						</Radio.Group>
					</div>
				</div>
			</Collapse.Panel>
		));
	};

	const renderCollapse = () => {
		if (activeBonusKey) {
			return (
				<Collapse
					className="bonus-collapse"
					bordered={false}
					expandIconPosition="end"
					activeKey={activeBonusKey}
				>
					{renderReports()}
				</Collapse>
			);
		}

		return (
			<Collapse
				className="bonus-collapse"
				bordered={false}
				expandIconPosition="end"
			>
				{renderReports()}
			</Collapse>
		);
	};

	// margin added to push the component below the report builder component
	return (
		<div className="custom-report-container">
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					marginBottom: 20,
				}}
			>
				<Button
					type="link"
					className="add-btn btn-lg"
					onClick={addCustomReport}
				>
					<span className="icon-circle">
						<i className="icon icon-plus" />
					</span>
					Add Custom Report
				</Button>
			</div>
			<Form>{renderCollapse()}</Form>
		</div>
	);
};
