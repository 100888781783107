import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Auth, Cache } from 'aws-amplify';
import AwsAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { ApolloProvider } from 'react-apollo';
import { Rehydrated } from 'aws-appsync-react';
import axios from 'axios';
import get from 'lodash/get';
import {
	downloadFromS3Signed,
	parseJwt,
	graphql,
} from 'src/_shared/services/utils.js';
import { awsApiKey } from './awsExports.js';
import DefaultSpinner from './DefaultSpinnerComponent.jsx';
import { decryptUsingAES256 } from './settings.js';
// Set ForceProdEnv true to force prod backend in development. //
import { configMode } from './configMode.js';

let { host } = window.location;
host = host.replace('www.', '');

if (
	host == 'heartlanddentalcareers.com' ||
	host == 'heartlandaffiliationreferrals.com'
) {
	window.location.href = `https://heartlanddentalreferrals.com/login`;
}

const awsMeta = awsApiKey;

const refreshJwtToken = async (authType) => {
	try {
		if (authType === 'default') {
			const session = await Auth.currentSession();
			const token = session.getIdToken().getJwtToken();
			Cookies.set('jwt', token);
		} else {
			const federatedToken = Cache.getItem('federatedToken');
			const refreshToken = federatedToken.refresh_token;
			const domain = Cache.getItem('federatedDomain');
			const region = Cache.getItem('federatedRegion');
			const clientId = Cache.getItem('federatedClientId');
			const clientSecret = Cache.getItem('federatedClientSecret');
			const scope = 'aws.cognito.signin.user.admin+email+openid+phone+profile';
			const body = `client_id=${clientId}&client_secret=${clientSecret}&grant_type=refresh_token&refresh_token=${refreshToken}&scope=${scope}`;
			const url = `https://${domain}.auth.${region}.amazoncognito.com/oauth2/token`;
			const response = await axios.post(url, body, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization:
						'Basic ' +
						Buffer.from(clientId + ':' + clientSecret).toString('base64'),
				},
			});
			const tokens = response.data;
			tokens.refresh_token = refreshToken;
			Cache.setItem('federatedToken', tokens);
			const token = tokens.id_token;
			const expires_at = tokens.expires_in * 1000 + Date.now();
			const federatedRefreshToken = {
				token,
				expires_at,
			};
			Cache.setItem('federatedRefreshToken', federatedRefreshToken);
			return federatedRefreshToken;
		}
	} catch {
		console.log('Session Expired!');
		window.location.href = `${window.location.origin}/logout`;
	}
};

const getJwtToken = (authType) => {
	let jwtToken = null;
	if (authType === 'default') {
		// Username and password login
		jwtToken = async () => {
			return (await Auth.currentSession()).getAccessToken().getJwtToken();
		};
	} else if (authType === 'saml') {
		// Saml login
		const federatedToken = Cache.getItem('federatedToken');
		jwtToken = async () => {
			const token = federatedToken
				? federatedToken.id_token
				: (await Auth.currentAuthenticatedUser()).token;
			const tokenData = parseJwt(token);
			let refreshToken = Cache.getItem('federatedRefreshToken');
			const exp = get(tokenData, 'exp');
			if (
				refreshToken &&
				new Date(get(refreshToken, 'expires_at')).getTime() <= Date.now()
			) {
				refreshToken = await refreshJwtToken(authType);
			} else if (!refreshToken && exp <= Date.now() / 1000) {
				refreshToken = await refreshJwtToken(authType);
			}

			return refreshToken ? refreshToken.token : token;
		};
	}

	return jwtToken;
};

const startTokenRefreshTimer = (authType) => {
	const REFRESH_INTERVAL = 4.5 * 60 * 1000;
	setInterval(async () => {
		if (Cookies.get('jwt') || Cache.getItem('federatedToken')) {
			try {
				await refreshJwtToken(authType);
			} catch (error) {
				console.error('Error refreshing token in timer:', error);
			}
		}
	}, REFRESH_INTERVAL);
};

const createClient = (authType) => {
	const jwtToken = getJwtToken(authType);

	return new AwsAppSyncClient({
		region: awsMeta.region,
		url: decryptUsingAES256(
			process.env[`REACT_APP_${configMode}_APPSYNCENDPOINT`]
		),
		auth: {
			type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
			jwtToken,
		},
		disableOffline: true,
		// link: ApolloLink.from([errorLink]),
	});
};

export function WithApiProvider({ Component }) {
	const [client, setClient] = useState(null);
	const [saml, setSaml] = useState(undefined);
	const [company, setCompany] = useState(null);
	const [key, setKey] = useState(0);

	useEffect(() => {
		const initializeClient = async () => {
			let authType = localStorage.getItem('authType');
			if (!authType) {
				authType = 'default';
				localStorage.setItem('authType', authType);
			}

			try {
				const newClient = createClient(authType);
				setClient(newClient);
				startTokenRefreshTimer(authType);

				awsMeta.url = decryptUsingAES256(
					process.env[`REACT_APP_${configMode}_APPSYNCENDPOINT`]
				);

				// START Getting company data by host using lambda
				let companyData;
				try {
					companyData = await graphql({
						input: { host },
						query: 'getCompanyByHost',
					});
					setCompany(companyData);
				} catch (error) {
					console.error(error);
				}

				// END Getting company dta by host using lambda
				// START setting favicon and title
				const favicon = document.querySelector('#favicon');
				const favTitle = document.querySelector('#faviconTitle');
				switch (host) {
					case 'app.erinapp.com': {
						favTitle.innerHTML = 'ERIN | Employee Referral Platform';
						favicon.setAttribute('href', '/erin_square.png');

						break;
					}

					case 'referrals.aus.com':
					case 'referralstest.aus.com': {
						favTitle.innerHTML = 'Allied Referrals';
						favicon.setAttribute('href', '/alliedfavicon.ico');

						break;
					}

					case 'referrals.pierpoint.com': {
						favTitle.innerHTML = 'Pierpoint | Referral Portal';
						favicon.setAttribute('href', '/Pierpoint.png');

						break;
					}

					case 'app.refervets.org': {
						favTitle.innerHTML = 'Refervets | Veteran Referral Network';
						favicon.setAttribute('href', '/refervets.png');

						break;
					}

					case 'referafriend.seaworldentertainment.com': {
						favTitle.innerHTML = 'Join Me At SEA';
						favicon.setAttribute('href', '/seaworld-favicon.ico');

						break;
					}

					case 'qa.referafriend.seaworldentertainment.com': {
						favTitle.innerHTML = 'Join Me At SEA';
						favicon.setAttribute('href', '/seaworld-favicon.ico');

						break;
					}

					case 'connect.myhatchpad.com': {
						favTitle.innerHTML = 'Hatchpad Connect';
						favicon.setAttribute('href', '/hatchpad.ico');

						break;
					}

					case 'referrals.sunriseseniorliving.com': {
						favTitle.innerHTML = 'Sunrise Employee Referrals';
						favicon.setAttribute('href', '/sunrise-favicon.ico');

						break;
					}

					case 'pinteresttalenthub.com': {
						favTitle.innerHTML =
							'Pinterest Talent Hub | Employee Referral Portal';
						favicon.setAttribute('href', '/pinterest_favicon.png');

						break;
					}

					case 'referrals.primaryaimllc.com': {
						favTitle.innerHTML = 'Primary Aim Referrals';
						favicon.setAttribute('href', '/primary_aim.jpeg');

						break;
					}

					default: {
						favTitle.innerHTML = companyData?.faviconTitle
							? companyData?.faviconTitle
							: 'ERIN | Employee Referral Portal';
						if (companyData?.favicon?.key) {
							const s3url = await downloadFromS3Signed(
								companyData.favicon.key,
								companyData.favicon.bucket
							);
							favicon.setAttribute('href', s3url);
						} else {
							favicon.setAttribute('href', '/erin_square.png');
						}
					}
				}

				// END setting favicon and title
				setSaml(
					await graphql({
						input: { companyId: companyData?.id },
						query: 'getSAMLAuthByCompanyId',
					})
				);
			} catch (error) {
				console.log(error);
			}
		};

		initializeClient();
	}, [key]);

	const handleSignOut = async () => {
		try {
			await Auth.signOut();
			localStorage.clear();
			Cache.clear();
			Cookies.remove('jwt');
			await client.clearStore();
			await client.resetStore();

			setKey((prevKey) => prevKey + 1); // To force reinit of apollo client
		} catch (error) {
			console.log('Error signing out', error);
		}
	};

	if (!client || saml === undefined) {
		return (
			<div
				style={{ height: '100%', width: '100%', backgroundColor: '#1b365c' }}
			>
				<DefaultSpinner />
			</div>
		);
	}

	return (
		<ApolloProvider client={client}>
			<Rehydrated
				render={({ rehydrated }) =>
					rehydrated ? (
						<Component
							secrets={{
								GoogleAuthClientID:
									process.env[`REACT_APP_${configMode}_GOOGLEAUTHCLIENTID`],
							}}
							handleSignOut={handleSignOut}
						/>
					) : (
						<div
							style={{
								height: '100vh',
								width: '100vw',
								backgroundColor: '#1b365c',
							}}
						>
							<DefaultSpinner />
						</div>
					)
				}
			/>
		</ApolloProvider>
	);
}
