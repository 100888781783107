import { Form, Icon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Input, message, Select, Space, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { SSO } from '../_shared/index.js';

const { Option } = Select;

const attributeOptions = [
	{ id: 'custom:country', label: 'Country' },
	{ id: 'custom:department', label: 'Department' },
	{ id: 'email', label: 'Email' },
	{ id: 'custom:employeeid', label: 'Employee ID' },
	{ id: 'given_name', label: 'First Name' },
	{ id: 'family_name', label: 'Last Name' },
	{ id: 'custom:jobtitle', label: 'Job Title' },
	{ id: 'custom:subCompany', label: 'Sub Company' },
	{ id: 'custom:usergroupalt', label: 'User Group' },
];
let defaultKeys = {
	email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
	family_name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
	given_name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
};
class SettingsSSOForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customHostName: this.props?.company?.host,
			setFolderName: false,
			xmlContent: '',
		};
	}

	async componentDidMount() {
		const { form } = this.props;
		const ssoConfig = await SSO({
			companyId: this.props?.currentUser?.companyId,
			type: 'getSSO',
		});
		defaultKeys = ssoConfig?.attributeMapping || defaultKeys;
		// Initialize the keys array with indexes based on the defaultKeys
		const keys = Object.keys(defaultKeys).map((key) => key);
		// Set the keys field
		if (ssoConfig && ssoConfig.length > 0) {
			const state = {
				companyCode: ssoConfig?.[0]?.companyCode,
				customHostName: ssoConfig?.[0]?.domain,
				xmlContent: ssoConfig?.[0]?.metadataFile || '',
				ssoConfig,
			};
			form.setFieldsValue(state);
			this.setState(state);
		}

		form.setFieldsValue({ keys });

		// Populate form fields with default values
		for (const [index, key] of Object.keys(defaultKeys).entries()) {
			form.setFieldsValue({
				[`attributeMapping[${index}].key`]: key,
				[`attributeMapping[${index}].value`]: defaultKeys[key],
			});
		}
	}

	add = () => {
		const { form } = this.props;
		const keys = form.getFieldValue('keys');
		const nextKeys = keys.concat(keys.length);
		form.setFieldsValue({
			keys: nextKeys,
		});
	};

	remove = (k) => {
		const { form } = this.props;
		const keys = form.getFieldValue('keys');
		if (keys.length === 1) {
			return;
		}

		form.setFieldsValue({
			keys: keys.filter((key) => key !== k),
		});
		delete defaultKeys[k];
	};

	handleSubmit = async () => {
		this.props.form.validateFields(async (error, values) => {
			if (error) {
				console.log(error);
				return;
			}

			const ssoSettings = { companyId: this.props.currentUser.companyId };
			if (values.companyCode) ssoSettings.companyCode = values.companyCode;
			if (values.customHostName)
				ssoSettings.customHostName = values.customHostName;
			if (this.state.xmlContent)
				ssoSettings.metadataFile = this.state.xmlContent;
			if (values.metadataUrl) ssoSettings.metadataUrl = values.metadataUrl;
			if (values.attributeMapping) {
				ssoSettings.attributeMapping = {};
				Object.entries(values.attributeMapping).map((entry) => {
					ssoSettings.attributeMapping[entry[1].key] = entry?.[1]?.value;
				});
			}

			ssoSettings.type = 'createSSO';
			const response = await SSO(ssoSettings);
			message.success('Changes Saved');
			return response;
		});
	};

	handleSubmitUpdateHost = async () => {
		const { form } = this.props;
		const { customHostName = null } = form.getFieldsValue();
		const ssoSettings = { companyId: this.props.currentUser.companyId };
		ssoSettings.customHostName = customHostName;
		ssoSettings.type = 'updateDomain';
		const response = await SSO(ssoSettings);
		message.success('Host Saved');
		return response;
	};

	validateUrl = (rule, url, callback) => {
		if (url) {
			try {
				// Add default scheme if missing
				const hostnameRegex =
					/^(?!-)[A-Za-z\d-]+(\.[A-Za-z\d-]+)*\.[A-Za-z]{2,}$/;

				if (!hostnameRegex.test(url) && url !== 'localhost:3000')
					callback('The input is not a valid host name!');
				const customHostName = url
					.replace(/https?:\/\//, '')
					.replace(/\/$/, '');
				if (this.state.customHostName !== customHostName) {
					this.setState({ customHostName });
					this.props.form.setFieldsValue({
						customHostName,
					});
				}

				callback(); // Validation passed
			} catch {
				callback('The input is not a valid host name!');
			}
		} else {
			callback();
		}
	};

	validateMetadata = (rule, url, callback) => {
		const { getFieldValue } = this.props.form;
		const MetadataURL = getFieldValue(`metadataUrl`);
		const MetadataFile = getFieldValue(`metadataFile`);
		if (!MetadataURL && !this.state.xmlContent && !MetadataFile) {
			callback('Please enter a Metadata URL or upload a Metadata XML File.');
		} else {
			callback();
		}
	};

	render() {
		const { setFolderName, ssoConfig, xmlContent } = this.state;
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const FormItem = Form.Item;
		getFieldDecorator('keys', { initialValue: [] });
		const keys = getFieldValue('keys');
		const handleFileChange = (info) => {
			this.setState({ metadataFile: info });
			const file = info.file.originFileObj || info.file; // Ensure we're getting the File object

			if (file instanceof Blob) {
				const reader = new FileReader();
				reader.addEventListener('load', (e) => {
					this.setState({ xmlContent: e.target.result });
				});

				reader.readAsText(file);
			} else {
				console.error('The selected file is not a valid Blob or File.');
			}
		};

		return (
			<>
				<Form>
					<h4 className="setting-card-title">Host Settings</h4>
					<div className="custom-form-group">
						<label className="custom-label">Custom Host Name: </label>
						<FormItem>
							{getFieldDecorator('customHostName', {
								initialValue: this.state.customHostName || null,
								rules: [{ validator: this.validateUrl }],
							})(
								<Input
									className="custom-input"
									onInput={(input) => {
										this.setState({ customHostName: input.target.value });
									}}
								/>
							)}
						</FormItem>
						<Button
							type="primary"
							size="large"
							className="btn-min-width"
							style={{ marginTop: 15, marginBottom: 10 }}
							onClick={this.handleSubmitUpdateHost}
						>
							Save Host
						</Button>
					</div>
					<h4 className="setting-card-title">SSO Settings</h4>
					<div className="custom-form-group">
						<label className="custom-label">Company Code:</label>
						<FormItem>
							{getFieldDecorator('companyCode', {
								initialValue: this.state.companyCode || null,
								rules: [
									{
										required: true,
										message: 'Please input company code.',
									},
								],
							})(
								<Input
									className="custom-input"
									onInput={(input) =>
										this.setState({ companyCode: input.target.value })
									}
								/>
							)}
						</FormItem>
					</div>

					<h4 className="setting-card-title">Attribute Mapping:</h4>
					{keys.map((k, index) => {
						const attribute = Object.keys(defaultKeys)[index];
						const claim = defaultKeys[attribute];

						return (
							<Space
								key={k}
								style={{ display: 'flex', marginBottom: 8 }}
								align="baseline"
							>
								<Form.Item label="Attribute">
									{getFieldDecorator(`attributeMapping[${k}].key`, {
										initialValue: attribute,
										rules: [
											{ required: true, message: 'Missing attribute name' },
										],
									})(
										<Select
											placeholder="Select an attribute"
											style={{ width: 250 }}
										>
											{attributeOptions.map((option) => (
												<Option key={option.id} value={option.id}>
													{option.label}
												</Option>
											))}
										</Select>
									)}
								</Form.Item>
								<Form.Item label="Mapping">
									{getFieldDecorator(`attributeMapping[${k}].value`, {
										initialValue: claim, // Prefill the claim URL
										rules: [{ required: true, message: 'Missing Mapping' }],
									})(
										<Input placeholder="SAML Mapping" style={{ width: 400 }} />
									)}
								</Form.Item>
								{keys.length > 1 ? (
									<Icon
										className="dynamic-delete-button"
										type="minus-circle-o"
										onClick={() => this.remove(k)}
									/>
								) : null}
							</Space>
						);
					})}
					<Form.Item>
						<Button type="dashed" onClick={this.add}>
							<Icon className="dynamic-delete-button" type="plus" />
							Add Attribute Mapping
						</Button>
					</Form.Item>
					<h4 className="setting-card-title">Metadata:</h4>
					<div className="custom-form-group">
						<label className="custom-label">Metadata URL: </label>
						<FormItem>
							{getFieldDecorator('metadataUrl', {
								initialValue: null,
								rules: [{ validator: this.validateMetadata }],
							})(
								<Input
									className="custom-input"
									onInput={(input) =>
										this.setState({ metadataUrl: input.target.value })
									}
								/>
							)}
						</FormItem>
					</div>
					<div className="custom-form-group">
						<label className="custom-label">Metadata File:</label>
						{getFieldDecorator('metadataFile', {
							rules: [{ validator: this.validateMetadata }],
						})(
							<Upload
								accept=".xml"
								beforeUpload={() => false}
								fileList={
									ssoConfig?.[0]?.metadataFile
										? [
												{
													uid: '-1',
													name: 'metadata.xml',
													status: 'done',
													url: (() => {
														// Create a Blob from the XML string
														if (!ssoConfig?.[0]?.metadataFile) return null;
														const xmlBlob = new Blob(
															[ssoConfig?.[0]?.metadataFile],
															{
																type: 'application/xml',
															}
														);
														// Generate a download link for the Blob
														return URL.createObjectURL(xmlBlob);
													})(),
												},
											]
										: []
								}
								onChange={(info) => this.handleFileChange(info, index)}
							>
								<Button icon={<UploadOutlined />}>
									Click to Upload XML File
								</Button>
							</Upload>
						)}
					</div>
					<div>
						<Button
							type="primary"
							size="large"
							className="btn-min-width"
							style={{ marginTop: 15, marginBottom: 10 }}
							onClick={this.handleSubmit}
						>
							Save All
						</Button>
					</div>
				</Form>
				{setFolderName ? (
					<Alert
						message="Your updates were successfully submitted."
						type="success"
					/>
				) : null}
			</>
		);
	}
}
export default Form.create()(withApollo(SettingsSSOForm));
